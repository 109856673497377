import React from "react";
import { Header } from "@/components/Header";
import { TwitterHandle } from "@/components/TwitterHandle";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import ImageDisplay from "@/components/Images/ImageDisplay";
import People from "@/components/People/People";
import { EventTable } from "@/components/EventDisplay/EventTable";
import FileDisplay from "@/components/Files/FileDisplay";
import CommentDisplay from "@/components/Comments/CommentDisplay";
import LoadingPulse from "@/components/shared/LoadingPulse";

export const EntityDetailLayout = ({
  isLoading,
  isFadingOut,
  entityType,
  entityData,
  breadcrumbs,
  images,
  handleCreateImage,
  deleteImage,
  people,
  toggleMainPOC,
  events,
  pastEvents,
  files,
  comments,
  handleCommentSubmit,
  perms,
  onEdit,
  canEdit,
}) => {
  return (
    <div className="relative min-h-screen bg-cave-white dark:bg-cave-black">
      {isLoading && <LoadingPulse />}
      <div
        className={`transition-opacity duration-1000 ${
          isLoading && !isFadingOut ? "opacity-0" : "opacity-100"
        }`}
      >
        <Header />
        <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0 text-black dark:text-cave-white">
          <BreadCrumbs links={breadcrumbs} />

          <div className="py-6 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl font-bold leading-7 dark:text-cave-white sm:truncate sm:text-3xl sm:tracking-tight">
                {images?.data && images.data.length > 0 && (
                  <img
                    width="200"
                    height="200"
                    src={images.data[0].image_url}
                  />
                )}
                {entityData.name}
              </h2>
              <h3 className="my-2 text-gray-600 dark:text-gray-400">
                {entityData.address}
              </h3>
              <h3 className="my-2 text-gray-600 dark:text-gray-400">
                {entityData.city && <>{entityData.city},</>} {entityData.state}{" "}
                {entityData.zipcode}
              </h3>
              <TwitterHandle url={entityData.twitter} />
            </div>
            <div className="mt-4 flex md:ml-4 md:mt-0 justify-start align-top">
              {canEdit && (
                <button
                  type="button"
                  onClick={onEdit}
                  className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-cave-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  Edit {entityType}
                </button>
              )}
            </div>
          </div>

          <div className="py-6">
            {entityData.description && (
              <p className="mb-4 dark:text-cave-white">
                {entityData.description}
              </p>
            )}
            <table className="table-auto w-3/4 dark:text-cave-white">
              <tbody>
                <tr>
                  <th className="text-left mr-8 p-2 w-1/4">URL</th>
                  <td className="dark:text-cave-white">
                    <a
                      target="_blank"
                      href={entityData.url}
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      {entityData.url}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th className="text-left mr-8 p-2 w-1/4">Email</th>
                  <td className="dark:text-cave-white">{entityData.email}</td>
                </tr>
                <tr>
                  <th className="text-left mr-8 p-2 w-1/4">Phone</th>
                  <td className="dark:text-cave-white">{entityData.phone}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="py-6">
            <ImageDisplay
              images={images}
              createImage={handleCreateImage}
              deleteQuery={deleteImage}
              editable={perms.data[`edit_${entityType.toLowerCase()}s`]}
              entityType={`${entityType.toLowerCase()}s`}
              id={entityData.id}
            />
            <People
              people={people}
              toggleMainPOC={toggleMainPOC}
              addURL={`/${entityType.toLowerCase()}s/people/add/${
                entityData.id
              }`}
              itemType={entityType.toLowerCase()}
              editable={perms.data[`edit_${entityType.toLowerCase()}s`]}
            />
          </div>

          <div className="py-6">
            <h3 className="text-xl font-semibold mb-6 dark:text-cave-white">
              Events
            </h3>
            {events.isLoading ? (
              <div className="text-center text-gray-700 dark:text-gray-300">
                Loading...
              </div>
            ) : (
              events.isSuccess && (
                <EventTable
                  events={events.data?.json || []}
                  defaultSortDirection="asc"
                />
              )
            )}
          </div>

          <div className="py-6">
            <h3 className="text-xl font-semibold mb-6 dark:text-cave-white">
              Past Events
            </h3>
            {pastEvents.isLoading ? (
              <div className="text-center text-gray-700 dark:text-gray-300">
                Loading...
              </div>
            ) : (
              pastEvents.isSuccess && (
                <EventTable
                  events={pastEvents.data?.json || []}
                  past={true}
                  defaultSortDirection="desc"
                />
              )
            )}
          </div>

          <div className="py-6">
            <FileDisplay
              files={files}
              id={entityData.id}
              entityType={`${entityType.toLowerCase()}s`}
              editable={perms.data[`edit_${entityType.toLowerCase()}s`]}
              perms={perms}
            />
          </div>

          <div className="py-6">
            <CommentDisplay
              comments={comments}
              handleAddComment={handleCommentSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
