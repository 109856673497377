import React, { useEffect, useRef, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useUser, usePerms } from "@/components/Auth";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@/components/Button";
import styles from "@/views/events/EventDetail.module.css";
import classNames from "classnames";
import {
  addEventToOffer,
  createOffer,
  getEventOfferVariables,
  getOffer,
  getOffers,
  updateEvent,
  deleteEventPeople,
  getEventPeople,
  getEvent,
} from "../../queries/events";
import {
  getEventAdvance,
  createEventAdvance,
  updateEventAdvance,
  deleteEventAdvance,
} from "../../queries/advances";
import {
  PencilSquareIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";

import { getEventRollups, getEventCopros } from "@/queries/accounting";

const ItemTable = ({
  rows = [],
  header,
  info,
  columns = [],
  color = "gray",
  highlightedColumn,
  onUpdate,
  tableId,
  showTable,
  columnWidths = ["200px", "auto"],
  showChevrons = true,
}) => {
  const [editableRows, setEditableRows] = useState(rows);

  const thClasses = classNames(
    "cursor-pointer text-center font-bold text-white text-md font-inter",
    { "bg-[#4E6B85] hover:bg-[#3A4F61]": color === "gray" },
    { "bg-red-500 hover:bg-red-700": color === "red" }
  );

  const handleBlur = (accessor, e, row) => {
    onUpdate && onUpdate(accessor, e.target.textContent);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  };

  const handleHeaderChange = (event, rowIndex) => {
    const newRows = [...editableRows];
    newRows[rowIndex].header = event.target.value;
    setEditableRows(newRows);
  };

  return (
    <table
      className="min-w-full rounded-lg overflow-hidden table-fixed"
      style={{ tableLayout: "fixed", width: "100%" }}
    >
      <colgroup>
        {columnWidths.map((width, idx) => (
          <col key={idx} style={{ width }} />
        ))}
      </colgroup>

      <thead>
        {header && (
          <tr className="h-10">
            <th className={thClasses} colSpan={columns.length || 1}>
              <div className="flex justify-center items-center">
                <span>{header.value}</span>
                {showChevrons &&
                  (showTable ? (
                    <ChevronDownIcon className={styles.chevronAccordionDown} />
                  ) : (
                    <ChevronUpIcon className={styles.chevronAccordionUp} />
                  ))}
              </div>
            </th>
          </tr>
        )}
        {showTable && columns.length > 0 && (
          <tr className="bg-[#f3836f]">
            {columns.map((col, idx) => (
              <th
                key={idx}
                className={`border-r border-black text-center dark:text-white uppercase ${styles.heavyFont}`}
              >
                {col.header}
              </th>
            ))}
          </tr>
        )}
      </thead>
      {showTable && (
        <tbody className="w-full">
          {info && (
            <tr className="w-full h-[240px]">
              <td
                colSpan={columns.length || 1}
                className="bg-white border-b border-[black] text-center text-sm font-inter whitespace-pre-line fton-[900] text-[1.2rem] leading-[1.5]"
              >
                {info}
              </td>
            </tr>
          )}
          {editableRows.length > 0 ? (
            editableRows.map((row, index) => (
              <tr
                className={`${
                  index % 2 !== 0 ? "bg-gray-100" : "bg-white"
                } h-12`}
                key={index}
              >
                {columns.map((col, colIdx) => (
                  <td
                    key={colIdx}
                    className={`border-r border-black p-1 px-3 text-sm font-inter ${
                      colIdx === 0
                        ? "text-black font-[900]"
                        : "text-black font-[400]"
                    }`}
                    contentEditable={colIdx !== 0 && row.editable}
                    suppressContentEditableWarning={true}
                    onBlur={(e) => handleBlur(col.accessor, e, row)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    style={{
                      whiteSpace: "wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {col.accessor === "header" && row.isEditableHeader ? (
                      <input
                        type="text"
                        value={row.header}
                        onChange={(e) => handleHeaderChange(e, index)}
                        className="bg-transparent border-none p-0 m-0 text-center text-black font-[900]"
                        style={{
                          width: "100%",
                          outline: "none",
                          textAlign: "left",
                          textTransform: "uppercase",
                        }}
                      />
                    ) : (
                      row[col.accessor]
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length || 1}
                className="bg-white border-b border-black text-center text-sm font-normal whitespace-pre-line h-12"
              >
                NO DATA
              </td>
            </tr>
          )}
        </tbody>
      )}
    </table>
  );
};

export const EventAdvance = (props) => {
  const [offerID, setOfferID] = useState(null);
  const [grossPotential, setGrossPotential] = useState(0);
  const [ticketFees, setTicketFees] = useState(0);
  const [openTable, setOpenTable] = useState(null);
  const queryClient = useQueryClient();
  const { user } = useUser();
  const [showTable2, setShowTable2] = useState(true);
  const [viewMode, setViewMode] = useState("all");

  const event = useQuery(["event-detail", props.id], () => getEvent(props.id), {
    onError: (error) => {
      console.error("Failed to load event:", error);
    },
  });

  const advance = useQuery(
    ["event-advance", props.id],
    () => getEventAdvance(props.id),
    {
      onError: (error) => {
        console.error("Failed to load advance data:", error);
      },
      onSuccess: (data) => {
        if (!data && event.data) {
          // If no advance exists, create one
          createAdvanceMutation.mutate({
            event_id: props.id,
            schedule: event.data.schedule || {},
            // Add other default fields as needed
          });
        }
      },
    }
  );

  const createAdvanceMutation = useMutation({
    mutationFn: async (data) => {
      return await createEventAdvance(props.id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-advance", props.id]);
    },
    onError: (error) => {
      console.error("Failed to create advance:", error);
    },
  });
  const latestOffer = event.data.offers.sort(
    (a, b) => new Date(b.modified) - new Date(a.modified)
  )[0];

  //const offerID = latestOffer ? latestOffer.id : null;
  const variables = useQuery(["variables-offer-pdf", props.id], () =>
    getEventOfferVariables(props.id)
  );
  const rollups = useQuery(["event-rollups", props.id], () =>
    getEventRollups(props.id)
  );
  const copros = useQuery(["event-copros", props.id], () =>
    getEventCopros(props.id)
  );
  const people = useQuery(["event-people", props.id], () =>
    getEventPeople(props.id)
  );

  const [isClubViewOpen, setClubViewOpen] = useState(false);
  const [isArenaViewOpen, setArenaViewOpen] = useState(false);
  const [clubViewTitle, setClubViewTitle] = useState("Club/Small Theater View");
  const [arenaViewTitle, setArenaViewTitle] = useState(
    "Arena Ampitheater View"
  );
  const [shouldRenderArenaView, setShouldRenderArenaView] = useState(true);
  const [shouldRenderClubView, setShouldRenderClubView] = useState(true);

  const toggleClubView = () => {
    setClubViewOpen(!isClubViewOpen);
    if (!isClubViewOpen) {
      setArenaViewOpen(false);
    }
  };

  const toggleArenaView = () => {
    setArenaViewOpen(!isArenaViewOpen);
    if (!isArenaViewOpen) {
      setClubViewOpen(false);
    }
  };

  const handleToggle = () => {
    setViewMode(viewMode === "all" ? "selected" : "all");
  };

  const navigate = useNavigate();

  const createOfferMutation = useMutation({
    mutationFn: async (data) => {
      return await createOffer(data);
    },
    onSuccess: (data) => {
      setOfferID(data.id);
      let body = {
        event_id: event.data.id,
        offer_id: data.id,
      };
      addEventToOfferMutation.mutate(body);
    },
  });

  const addEventToOfferMutation = useMutation({
    mutationFn: async (data) => {
      return await addEventToOffer(parseInt(data.event_id), data.offer_id);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["offer-detail", offerID]);
    },
  });

  const updateAdvanceMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEventAdvance(props.id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-advance", props.id]);
    },
    onError: (error) => {
      console.error("Failed to update/create advance data:", error);
      // TODO: Add toast notification here
    },
    retry: false, // Disable automatic retries since we handle 404 in the query function
  });

  const updateEventMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEvent(props.id, data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["event-detail", props.id]);
      queryClient.invalidateQueries(["variables-offer-pdf", props.id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", props.id]);
      queryClient.invalidateQueries(["event-rollups", props.id]);
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", props.id]);
      const previousEventData = queryClient.getQueryData([
        "event-detail",
        props.id,
      ]);
      if (previousEventData && newData.schedule !== undefined) {
        queryClient.setQueryData(["event-detail", props.id], {
          ...previousEventData,
          schedule: newData.schedule,
        });
      }
      return { previousEventData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(
        ["event-detail", props.id],
        context.previousEventData
      );
    },
  });

  useEffect(() => {
    if (event && event.data) {
      setGrossPotential(
        event.data.manifests.reduce(
          (total, manifest) =>
            total + (manifest.price + manifest?.ticket_fees) * manifest.qty,
          0
        )
      );
      setTicketFees(
        event.data.manifests.reduce(
          (total, manifest) => total + manifest.qty * manifest?.ticket_fees,
          0
        )
      );
    }
  }, [event?.data]);

  useEffect(() => {
    if (event.isLoading || !event.data) {
      return;
    } else {
      const latestOffer = event.data.offers.sort(
        (a, b) => new Date(b.modified) - new Date(a.modified)
      )[0];

      const offerID = latestOffer ? latestOffer.id : null;

      if (latestOffer) {
        //setOfferID(latestOffer)
        queryClient.invalidateQueries(["offer-detail", latestOffer.id]);
      } else {
        //if (offerFound) {
        //  setOfferID(offerFound.id);
        //  queryClient.invalidateQueries(["offer-detail", offerFound.id]);
        //} else {
        createOfferMutation.mutate({
          production_adv: "production@mammothlive.com",
          local_marketing: "",
          ticketing: "ticketing@mammothlive.com",
          tour_admat: "",
          tour_marketing: "",
          onsale_info: "",
          presale_info: "",
          contracts: "contracts@mammothlive.com",
          draft: false,
          name: "New Offer",
          created: new Date().toISOString(),
          modified: new Date().toISOString(),
          buyer_id: user?.data?.id,
          created_by_id: user?.data?.id,
          events: [event.data.id],
          active: true,
          closed: false,
        });
        const latestOffer = event.data.offers.sort(
          (a, b) => new Date(b.modified) - new Date(a.modified)
        )[0];

        const offerID = latestOffer ? latestOffer.id : null;
      }
      //}, [offers.data, event.data]);
    }
  });

  const submitAdvanceMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEventAdvance(props.id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-advance", props.id]);
      // Update view titles after successful submission
      if (data.view === "club") {
        setClubViewTitle(`${event.data.name} / ${event.data.date} = Advance`);
        setShouldRenderArenaView(false);
      } else if (data.view === "arena") {
        setArenaViewTitle(`${event.data.name} - Advance`);
        setShouldRenderClubView(false);
      }
    },
    onError: (error) => {
      console.error("Failed to submit advance:", error);
      // TODO: Add error notification here
    },
  });

  const handleSubmitAdvance = async (view) => {
    try {
      if (!advance.data) {
        console.error("No advance data to submit");
        return;
      }

      const advanceData = {
        ...advance.data,
        event_id: props.id,
        view: view,
        schedule: event.data.schedule || {},
        // Add any other fields that need to be included
      };

      await submitAdvanceMutation.mutateAsync(advanceData);
    } catch (error) {
      console.error("Error submitting advance:", error);
      // TODO: Add error notification here
    }
  };

  if (
    event.isLoading ||
    variables.isLoading ||
    rollups.isLoading ||
    copros.isLoading ||
    people.isLoading
  ) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
        <span className="ml-2 dark:text-white">Loading Event Advance...</span>
      </div>
    );
  }

  if (
    event.isError ||
    variables.isError ||
    rollups.isError ||
    copros.isError ||
    people.isError
  ) {
    return <div className="dark:text-white">Error Loading Event Advance</div>;
  }

  // required params

  const EVENT_REQUIRED_PARAMS = {
    name: event.data.name,
    date: event.data.date,
    artists: event.data.artists ? [event.data.artists[0].id] : [0],
    state: event.data.state,
    age_requirement: event.data.age_requirement,
    abbr: event.data.abbr,
    description: event.data.description,
    door_time: event.data.door_time,
    opener_time: event.data.opener_time,
    support_time: event.data.support_time,
    headliner_time: event.data.headliner_time,
    curfew_time: event.data.curfew_time,
    offer_notes: event.data.offer_notes,
    confirmation_notes: event.data.confirmation_notes,
    venue_id: event.data.venue.id,
    has_insurance: event.data.has_insurance,
    has_credit_card_fees: event.data.has_credit_card_fees,
    has_ticket_commission: event.data.has_ticket_commission,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_sesac: event.data.has_sesac,
    offer_has_rent: event.data.offer_has_rent,
    offer_has_comp_tax: event.data.offer_has_comp_tax,
    schedule: event.data.schedule,
  };

  // handlers

  const handleEditButtonPress = () => {
    navigate(`/events/advance/edit/${event.data.id}`);
  };

  const handleUpdateSchedule = async (accessor, value) => {
    try {
      // Update event schedule
      EVENT_REQUIRED_PARAMS.schedule = Object.assign(
        EVENT_REQUIRED_PARAMS.schedule,
        { [accessor]: value }
      );
      await updateEventMutation.mutateAsync(EVENT_REQUIRED_PARAMS);

      // Update advance data
      if (advance.data) {
        const advanceData = {
          ...advance.data,
          event_id: props.id,
          schedule: {
            ...advance.data.schedule,
            [accessor]: value,
          },
        };
        await updateAdvanceMutation.mutateAsync(advanceData);
      }
    } catch (error) {
      console.error("Failed to update schedule:", error);
      // TODO: Add error notification here
    }
  };

  const handleGetPersonRoles = (roles) => {
    return people?.data
      ?.map((person) => {
        if (roles.includes(person.role_name)) {
          return {
            header: person.role_name,
            value: person.first_name + " " + person.last_name,
          };
        } else {
          return null;
        }
      })
      .filter((person) => person !== null);
  };

  const toggleTable = (tableId) => {
    setOpenTable(openTable === tableId ? null : tableId);
  };

  return (
    <div className="eventAdvanceWrapper border-t-[1px] border-[#595959] pt-[1rem]">
      <div className="flex flex-col gap-4 mb-12 mt-12">
        <div className="flex flex-row justify-between items-center mb-4">
          <h2 id="section2" className="text-xl font-bold dark:text-white">
            Event Advance
          </h2>
          <Button onClick={handleEditButtonPress}>Edit Advance</Button>
        </div>

        {/* Club/Small Theater View Accordion */}
        {shouldRenderClubView && (
          <div>
            <button
              className={`flex justify-between items-center w-full px-4 py-2 bg-[green] text-left text-lg font-semibold cursor-pointer rounded-[10px] ${styles.chevronAccordionDiv}`}
              onClick={toggleClubView}
            >
              <span className="m-auto dark:text-white">{clubViewTitle}</span>
              {isClubViewOpen ? (
                <ChevronDownIcon className={styles.chevronAccordionDown} />
              ) : (
                <ChevronUpIcon className={styles.chevronAccordionUp} />
              )}
            </button>

            {isClubViewOpen && (
              <div className="mt-4">
                <div className="grid grid-cols-12 grid-rows-48 gap-4">
                  {/* Event Info Table */}
                  <div
                    className={`col-start-1 col-span-3 row-start-1 row-span-2 ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("eventInfo")}
                  >
                    <ItemTable
                      header={{ value: "EVENT INFO" }}
                      rows={[
                        {
                          header: "HEADLINER",
                          value: event.data.offer_headliner,
                          editable: false,
                        },
                        {
                          header: "SUPPORT 1",
                          value: event.data.offer_support_1,
                          editable: false,
                        },
                        {
                          header: "SUPPORT 2",
                          value: event.data.offer_support_2,
                          editable: true,
                        },
                        {
                          header: "DAY/DATE",
                          value: new Date(event.data.date).toDateString(),
                          editable: false,
                        },
                        {
                          header: "HEADLINER TIME",
                          value: event.data.headliner_time,
                          editable: true,
                        },
                        {
                          header: "VENUE",
                          value: event.data.venue.name,
                          editable: false,
                        },
                        {
                          header: "ADDRESS",
                          value: event.data.venue.address,
                          editable: false,
                        },
                        {
                          header: "CITY/STATE/ZIP",
                          value: `${event.data.venue.city}, ${event.data.venue.state} ${event.data.venue.zipcode}`,
                          editable: false,
                        },
                        {
                          header: "MAIN PHONE",
                          value: event.data.venue.phone,
                          editable: true,
                        },
                        {
                          header: "CAPACITY",
                          value: event.data.venue.capacity,
                          editable: true,
                        },
                        {
                          header: "WEBSITE",
                          value: event.data.venue.website,
                          editable: true,
                        },
                        { header: "VENUE TYPE", value: "", editable: true },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "", accessor: "value" },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>
                  {/* Venue Contacts */}
                  <div
                    className={`col-start-4 col-span-9 row-start-1 row-span-1 ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("venueContacts")}
                  >
                    <ItemTable
                      header={{ value: "VENUE CONTACTS" }}
                      rows={[
                        {
                          header: "GM",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "MARKETING",
                          Name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "BOX OFFICE",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "PRODUCTION",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "CATERING",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "name" },
                        { header: "Phone", accessor: "phone" },
                        { header: "Cell", accessor: "cell" },
                        { header: "Email", accessor: "email" },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Tour Contacts */}
                  <div
                    className={`col-start-4 col-span-9 row-start-2 row-span-1  ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("tourContacts")}
                  >
                    <ItemTable
                      header={{ value: "TOUR CONTACTS" }}
                      rows={[
                        {
                          header: "TOUR MGR",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "PRODUCTION MGR",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "TOUR ACCOUNTANT",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "",
                          editable: true,
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "name" },
                        { header: "Phone", accessor: "phone" },
                        { header: "Cell", accessor: "cell" },
                        { header: "Email", accessor: "email" },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Schedule */}
                  <div
                    className={`col-start-1 col-span-3 row-start-3 row-span-2 ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("schedule")}
                  >
                    <ItemTable
                      header={{ value: "SCHEDULE" }}
                      rows={[
                        {
                          header: "GM",
                          name: "",
                          phone: "",
                          cell: "",
                          email: "",
                          editable: true,
                        },
                        {
                          header: "VENUE ACCESS",
                          accessor: "venue_access",
                          value: event.data.schedule?.venue_access,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "BREAKFAST",
                          accessor: "breakfast",
                          value: event.data.schedule?.breakfast,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "LOCAL LOAD IN",
                          accessor: "local_load_in",
                          value: event.data.schedule?.local_load_in,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "TOUR LOAD IN",
                          accessor: "tour_load_in",
                          value: event.data.schedule?.tour_load_in,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "LUNCH",
                          accessor: "lunch",
                          value: event.data.schedule?.lunch,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "SOUND CHECK",
                          accessor: "sound_check",
                          value: event.data.schedule?.sound_check,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "SECURITY MEETING",
                          accessor: "security_meeting",
                          value: event.data.schedule?.security_meeting,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "DINNER",
                          accessor: "dinner",
                          value: event.data.schedule?.dinner,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "DOORS",
                          accessor: "doors",
                          value: event.data.schedule?.doors,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "SET 1",
                          accessor: "set_1",
                          value: event.data.schedule?.set_1,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "INTERMISSION",
                          accessor: "intermission",
                          value: event.data.schedule?.intermission,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "SET 2",
                          accessor: "set_2",
                          value: event.data.schedule?.set_2,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "CALL BACK",
                          accessor: "call_back",
                          value: event.data.schedule?.call_back,
                          format: "time",
                          editable: true,
                        },
                        {
                          header: "CURFEW",
                          accessor: "curfew",
                          value: event.data.schedule?.curfew,
                          format: "time",
                          editable: true,
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "", accessor: "" },
                      ]}
                      color="red"
                      onUpdate={handleUpdateSchedule}
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Outside Vendors */}
                  <div
                    className={`col-start-4 col-span-9 row-start-3 row-span-1  ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("outsideVendors")}
                  >
                    <ItemTable
                      header={{ value: "OUTSIDE VENDORS" }}
                      rows={[
                        { header: "SOUND/LIGHTS", value: "", editable: true },
                        { header: " ", value: "", editable: true },
                        { header: " ", value: "", editable: true },
                        { header: "", value: "", editable: true },
                        { header: "", value: "", editable: true },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "name" },
                        { header: "Phone", accessor: "phone" },
                        { header: "Cell", accessor: "cell" },
                        { header: "Email", accessor: "email" },
                      ]}
                      color="red"
                      columnWidths={["150px", "300px", "200px"]}
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>

                  {/* Promoters */}
                  <div
                    className={`col-start-4 col-span-9 row-start-4 row-span-1  ${styles.chevronAccordionDiv}`}
                    onClick={() => toggleTable("promoters")}
                  >
                    <ItemTable
                      header={{ value: "PROMOTERS" }}
                      rows={[
                        {
                          header: "BUYER",
                          value: variables.data.buyer_name,
                          editable: false,
                        },
                        {
                          header: "PRODUCTION ADV",
                          value:
                            event.data.offers[0].production_adv ||
                            "production@mammothlive.com",
                          editable: false,
                        },
                        {
                          header: "TICKETING",
                          value: event.data.offers[0].ticketing,
                          editable: false,
                        },
                        {
                          header: "REP/SETTLEMENT",
                          value: "-",
                          editable: true,
                        },
                        {
                          header: "TOUR MARKETING",
                          value: event.data.offers[0].tour_marketing,
                          editable: true,
                        },
                        {
                          header: "LOCAL MARKETING",
                          value: event.data.offers[0].local_marketing,
                          editable: true,
                        },
                      ]}
                      columns={[
                        { header: "", accessor: "header" },
                        { header: "Name", accessor: "value" },
                        { header: "Phone", accessor: "" },
                        { header: "Cell", accessor: "" },
                        { header: "Email", accessor: "" },
                      ]}
                      color="red"
                      showTable={showTable2}
                      showChevrons={false}
                    />
                  </div>
                </div>
                <div className="mt-[1rem]">
                  <Button
                    onClick={() => handleSubmitAdvance("club")}
                    disabled={submitAdvanceMutation.isLoading}
                  >
                    {submitAdvanceMutation.isLoading
                      ? "Submitting..."
                      : "Submit Advance"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Arena/Ampitheater View Accordion Below Here*/}
        {shouldRenderArenaView && (
          <div>
            <button
              className={`lex justify-between items-center w-full px-4 py-2 bg-[blue] text-left text-lg font-semibold cursor-pointer rounded-[10px] ${styles.chevronAccordionDiv}`}
              onClick={toggleArenaView}
            >
              <span className="m-auto dark:text-white">{arenaViewTitle}</span>
              {isArenaViewOpen ? (
                <ChevronDownIcon className={styles.chevronAccordionDown} />
              ) : (
                <ChevronUpIcon className={styles.chevronAccordionUp} />
              )}
            </button>

            {isArenaViewOpen && (
              <div className="grid grid-cols-12 grid-rows-48 gap-4 mt-[1rem]">
                <div
                  className={`col-start-1 col-span-3 row-start-1 row-span-2 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("eventInfo")}
                >
                  <ItemTable
                    header={{ value: "EVENT INFO" }}
                    rows={[
                      {
                        header: "HEADLINER",
                        value: event.data.offer_headliner,
                        editable: false,
                      },
                      {
                        header: "SUPPORT 1",
                        value: event.data.offer_support_1,
                        editable: false,
                      },
                      {
                        header: "SUPPORT 2",
                        value: event.data.offer_support_2,
                        editable: true,
                      },
                      {
                        header: "DAY/DATE",
                        value: new Date(event.data.date).toDateString(),
                        editable: false,
                      },
                      {
                        header: "HEADLINER TIME",
                        value: event.data.headliner_time,
                        editable: true,
                      },
                      {
                        header: "VENUE",
                        value: event.data.venue.name,
                        editable: false,
                      },
                      {
                        header: "ADDRESS",
                        value: event.data.venue.address,
                        editable: false,
                      },
                      {
                        header: "CITY/STATE/ZIP",
                        value: `${event.data.venue.city}, ${event.data.venue.state} ${event.data.venue.zipcode}`,
                        editable: false,
                      },
                      {
                        header: "MAIN PHONE",
                        value: event.data.venue.phone,
                        editable: true,
                      },
                      {
                        header: "CAPACITY",
                        value: event.data.venue.capacity,
                        editable: true,
                      },
                      {
                        header: "WEBSITE",
                        value: event.data.venue.website,
                        editable: true,
                      },

                      { header: "VENUE TYPE", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "value" },
                    ]}
                    color="red"
                    // showTable={openTable === "eventInfo"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-1 row-span-1 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("venueContacts")}
                >
                  <ItemTable
                    header={{ value: "VENUE CONTACTS" }}
                    rows={[
                      {
                        header: "GM",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "MARKETING",
                        Name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "BOX OFFICE",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "PRODUCTION",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "CATERING",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "name" },
                      { header: "Phone", accessor: "phone" },
                      { header: "Cell", accessor: "cell" },
                      { header: "Email", accessor: "email" },
                    ]}
                    color="red"
                    // showTable={openTable === "venueContacts"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-2 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("tourContacts")}
                >
                  <ItemTable
                    header={{ value: "TOUR CONTACTS" }}
                    rows={[
                      {
                        header: "TOUR MGR",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "PRODUCTION MGR",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "TOUR ACCOUNTANT",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                      },
                      {
                        header: "",
                        editable: true,
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "name" },
                      { header: "Phone", accessor: "phone" },
                      { header: "Cell", accessor: "cell" },
                      { header: "Email", accessor: "email" },
                    ]}
                    // rows={[
                    //   { header: "TOUR MGR", value: "" },
                    //   { header: "PRODUCTION MGR", value: "" },
                    //   { header: "TOUR ACCOUNTANT", value: "" },
                    //   { header: "", value: "" },
                    //   { header: "", value: "" },
                    // ]}
                    // rows={handleGetPersonRoles[
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    //   "Tour Accountant",
                    //   "Tour Accountant",
                    // ]}
                    color="red"
                    // showTable={openTable === "tourContacts"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-3 row-start-3 row-span-2 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("schedule")}
                >
                  <ItemTable
                    header={{ value: "SCHEDULE" }}
                    rows={[
                      {
                        header: "GM",
                        name: "",
                        phone: "",
                        cell: "",
                        email: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "VENUE ACCESS",
                        accessor: "venue_access",
                        value: event.data.schedule?.venue_access,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "BREAKFAST",
                        accessor: "breakfast",
                        value: event.data.schedule?.breakfast,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "LOCAL LOAD IN",
                        accessor: "local_load_in",
                        value: event.data.schedule?.local_load_in,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "TOUR LOAD IN",
                        accessor: "tour_load_in",
                        value: event.data.schedule?.tour_load_in,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "LUNCH",
                        accessor: "lunch",
                        value: event.data.schedule?.lunch,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "SOUND CHECK",
                        accessor: "sound_check",
                        value: event.data.schedule?.sound_check,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "SECURITY MEETING",
                        accessor: "security_meeting",
                        value: event.data.schedule?.security_meeting,
                        format: "time",
                        editable: true,
                      },
                      {
                        header: "DINNER",
                        accessor: "dinner",
                        value: event.data.schedule?.dinner,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "DOORS",
                        accessor: "doors",
                        value: event.data.schedule?.doors,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "SET 1",
                        accessor: "set_1",
                        value: event.data.schedule?.set_1,
                        format: "time",
                        editable: true,
                      },
                      {
                        header: "INTERMISSION",
                        accessor: "intermission",
                        value: event.data.schedule?.intermission,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "SET 2",
                        accessor: "set_2",
                        value: event.data.schedule?.set_2,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "CALL BACK",
                        accessor: "call_back",
                        value: event.data.schedule?.call_back,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "CURFEW",
                        accessor: "curfew",
                        value: event.data.schedule?.curfew,
                        format: "time",
                        editable: true,
                        isEditableHeader: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    color="red"
                    onUpdate={handleUpdateSchedule}
                    // showTable={openTable === "schedule"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-3 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("outsideVendors")}
                >
                  <ItemTable
                    header={{ value: "OUTSIDE VENDORS" }}
                    rows={[
                      { header: "SOUND/LIGHTS", value: "", editable: true },
                      { header: " ", value: "", editable: true },
                      { header: " ", value: "", editable: true },
                      { header: "", value: "", editable: true },
                      { header: "", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "name" },
                      { header: "Phone", accessor: "phone" },
                      { header: "Cell", accessor: "cell" },
                      { header: "Email", accessor: "email" },
                    ]}
                    // rows={handleGetPersonRoles(["Sound", "Lights"])}
                    color="red"
                    columnWidths={["150px", "300px", "200px"]}
                    // showTable={openTable === "outsideVendors"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-4 col-span-9 row-start-4 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("promoters")}
                >
                  <ItemTable
                    header={{ value: "PROMOTERS" }}
                    rows={[
                      {
                        header: "BUYER",
                        value: variables.data.buyer_name,
                        editable: false,
                      },
                      {
                        header: "PRODUCTION ADV",
                        value:
                          event.data.offers[0].production_adv ||
                          "production@mammothlive.com",
                        editable: false,
                      },
                      {
                        header: "TICKETING",
                        value: event.data.offers[0].ticketing,
                        editable: false,
                      },
                      {
                        header: "REP/SETTLEMENT",
                        value: "-",
                        editable: true,
                      },
                      {
                        header: "TOUR MARKETING",
                        value: event.data.offers[0].tour_marketing,
                        editable: true,
                      },
                      {
                        header: "LOCAL MARKETING",
                        value: event.data.offers[0].local_marketing,
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "Name", accessor: "value" },
                      { header: "Phone", accessor: "" },
                      { header: "Cell", accessor: "" },
                      { header: "Email", accessor: "" },
                    ]}
                    color="red"
                    // showTable={openTable === "promoters"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-6 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("laborCalls")}
                >
                  <ItemTable
                    header={{ value: "LABOR CALLS" }}
                    rows={[
                      { header: "STEWARD", value: "", editable: true },
                      { header: "HANDS", value: "", editable: true },
                      { header: "LOADERS", value: "", editable: true },
                      { header: "RIGGERS (UP)", value: "", editable: true },
                      { header: "RIGGERS (DOWN)", value: "", editable: true },
                      { header: "ELECTRITION", value: "", editable: true },
                      { header: "WARDROBE", value: "", editable: true },
                      { header: "SPOT OPTS", value: "", editable: true },
                      { header: "HOUSE LIGHTS", value: "", editable: true },
                      { header: "FLY MAN", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "LOAD IN:", accessor: "name" },
                      { header: "SHOW CALL:", accessor: "phone" },
                      { header: "LOAD OUT:", accessor: "cell" },
                    ]}
                    // rows={handleGetPersonRoles([
                    // rows={["Tour Manager", "Production Manager", "Tour Accountant"]}
                    color="red"
                    // showTable={openTable === "laborCalls"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-7 col-span-3 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("laborRules1")}
                >
                  <ItemTable
                    header={{ value: "LABOR TYPE/RULES" }}
                    rows={[
                      { header: "CREW TYPE", value: "", editable: true },
                      { header: "STEWARD NAME", value: "", editable: true },
                      { header: "MIN IN", value: "", editable: true },
                      { header: "MIN SHOW", value: "", editable: true },
                      { header: "MIN OUT", value: "", editable: true },
                      { header: "CONVERT", value: "", editable: true },
                      { header: "FEED BY", value: "", editable: true },
                      { header: "MIN CREW", value: "", editable: true },
                      { header: "OVERTIME", value: "", editable: true },
                      { header: "DARK", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "laborRules1"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-10 col-span-3 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("laborRules2")}
                >
                  <ItemTable
                    header={{ value: "LABOR TYPE/RULES" }}
                    rows={[
                      { header: "CREW TYPE", value: "", editable: true },
                      { header: "STEWARD NAME", value: "", editable: true },
                      { header: "MIN IN", value: "", editable: true },
                      { header: "MIN SHOW", value: "", editable: true },
                      { header: "MIN OUT", value: "", editable: true },
                      { header: "CONVERT", value: "", editable: true },
                      { header: "FEED BY", value: "", editable: true },
                      { header: "MIN CREW", value: "", editable: true },
                      { header: "OVERTIME", value: "", editable: true },
                      { header: "DARK", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "laborRules2"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-4 row-start-8 row-span-3  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("tourContacts")}
                >
                  <ItemTable
                    header={{ value: "PRODUCTION PROVIDERS/NEEDS" }}
                    rows={[
                      { header: "RACK/STACKS", value: "", editable: true },
                      { header: "CONSOLES", value: "", editable: true },
                      { header: "LIGHTS", value: "", editable: true },
                      { header: "VIDEO", value: "", editable: true },
                      { header: "SPOTLIGHTS", value: "", editable: true },
                      { header: "CLEAR COMM", value: "", editable: true },
                      { header: "CURTAINS", value: "", editable: true },
                      { header: "BIKE RACK", value: "", editable: true },
                      { header: "BARRICADE", value: "", editable: true },
                      { header: "FORK LIFT", value: "", editable: true },
                      { header: "RISERS", value: "", editable: true },
                      { header: "STROBES", value: "", editable: true },
                      { header: "HAZE", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "tourContacts"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-5 col-span-4 row-start-8 row-span-3 ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("venuTechStageInfo")}
                >
                  <ItemTable
                    header={{ value: "VENU TECH/STAGE INFO" }}
                    rows={[
                      { header: "POWER #1", value: "", editable: true },
                      { header: "POWER #2", value: "", editable: true },
                      { header: "SHORE POWER", value: "", editable: true },
                      { header: "STAGE SIZE", value: "", editable: true },
                      {
                        header: "STAGE LEFT WING",
                        value: "",
                        editable: true,
                      },
                      {
                        header: "STAGE RIGHT WING",
                        value: "",
                        editable: true,
                      },
                      { header: "MIX AREA", value: "", editable: true },
                      { header: "MIX DISTANCE", value: "", editable: true },
                      { header: "PROCENIUM", value: "", editable: true },
                      {
                        header: "STEEL/GRID HEIGHT",
                        value: "",
                        editable: true,
                      },
                      { header: "LOAD-IN TYPE", value: "", editable: true },
                      { header: "PUSH DISTANCE", value: "", editable: true },
                      {
                        header: "# OF TRUCKS AT HOME",
                        value: "",
                        editable: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "venuTechStageInfo"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start-8 row-span-1  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("cateringinfo")}
                >
                  <ItemTable
                    header={{ value: "CATERING INFO" }}
                    rows={[
                      { header: "BREAKFAST", value: "", editable: true },
                      { header: "LUNCH", value: "", editable: true },
                      { header: "DRESSING ROOMS", value: "", editable: true },
                      { header: "DINNER", value: "", editable: true },
                      { header: "", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "cateringinfo"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start- row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("phonesInternet")}
                >
                  <ItemTable
                    header={{ value: "PHONES/INTERNET" }}
                    rows={[
                      { header: "PHONE NEEDS", value: "", editable: true },
                      { header: "INTERNET", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header", editable: true },
                      { header: "", accessor: "", editable: true },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "phonesInternet"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("dressingRoomNeeds")}
                >
                  <ItemTable
                    header={{ value: "DRESSING ROOM NEEDS" }}
                    rows={[
                      { header: "ROOM #1", value: "", editable: true },
                      { header: "ROOM #2", value: "", editable: true },
                      { header: "ROOM #3", value: "", editable: true },
                      { header: "FURNITURE", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "dressingRoomNeeds"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("parking")}
                >
                  <ItemTable
                    header={{ value: "PARKING" }}
                    rows={[
                      { header: "TRUCKS", value: "", editable: true },
                      { header: "BUSSES", value: "", editable: true },
                      { header: "VANS", value: "", editable: true },
                      { header: "CARS", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "parking"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-5 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("towelsShowersLaundry")}
                >
                  <ItemTable
                    header={{ value: "TOWELS/SHOWERS/LAUNDRY" }}
                    rows={[
                      {
                        header: "LAUNDRY ON-SITE",
                        value: "",
                        editable: true,
                      },
                      {
                        header: "SHOWERS ON-SITE",
                        value: "",
                        editable: true,
                      },
                      { header: "BATH TOWELS", value: "", editable: true },
                      { header: "HAND TOWELS", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "towelsShowersLaundry"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-9 col-span-4 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("estimates")}
                >
                  <ItemTable
                    header={{ value: "ESTIMATES" }}
                    rows={[
                      { header: "CATERING", value: "", editable: true },
                      { header: "HANDS", value: "", editable: true },
                      { header: "PRODUCTION", value: "", editable: true },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "estimates"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>

                <div
                  className={`col-start-1 col-span-6 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("notes")}
                >
                  <ItemTable
                    header={{ value: "NOTES" }}
                    rows={[
                      {
                        header: "Note 1",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "Note 2",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "Note 3",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "value" },
                    ]}
                    showTable={true}
                    showChevrons={false}
                    onUpdate={(accessor, value) =>
                      console.log(`Updated ${accessor}: ${value}`)
                    }
                    color="red"
                  />
                </div>

                <div
                  className={`col-start-7 col-span-6 row-start-8 row-span-7  ${styles.chevronAccordionDiv}`}
                  onClick={() => toggleTable("cashNeeds")}
                >
                  <ItemTable
                    header={{ value: "CASH NEEDS" }}
                    rows={[
                      {
                        header: "",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                      {
                        header: "",
                        value: "",
                        editable: true,
                        isEditableHeader: true,
                      },
                    ]}
                    columns={[
                      { header: "", accessor: "header" },
                      { header: "", accessor: "" },
                    ]}
                    // rows={handleGetPersonRoles([
                    //   "Tour Manager",
                    //   "Production Manager",
                    //   "Tour Accountant",
                    // ])}
                    color="red"
                    // showTable={openTable === "cashNeeds"}
                    showTable={showTable2}
                    showChevrons={false}
                  />
                </div>
                <div className="mt-[1rem]">
                  <Button
                    onClick={() => handleSubmitAdvance("arena")}
                    disabled={submitAdvanceMutation.isLoading}
                  >
                    {submitAdvanceMutation.isLoading
                      ? "Submitting..."
                      : "Submit Advance"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventAdvance;
