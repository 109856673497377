import React from "react";

export const LoadingPulse = () => {
  return (
    <div className="flex items-center justify-center h-screen w-screen fixed top-0 left-0 bg-cave-black z-50">
      <div className="relative w-28 h-28">
        <div className="absolute inset-0 scale-110 border-8 border-[#FFFBE6] rounded-full animate-[ping_4s_ease-in-out_infinite]"></div>
        <div className="absolute inset-0 scale-110 border-8 border-[#3F3F3F] rounded-full animate-[radialFade_4s_ease-in-out_infinite]"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            src="/static/images/company-logo-black-bg.svg"
            className="absolute h-12 w-auto z-10 dark:block animate-[pulse_4s_ease-in-out_infinite]"
            alt="Loading"
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingPulse;
