import React, { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { UserMenuItem } from "./UserMenuItem";
import { getMe, userQuerySettings, logoutUser } from "@/queries/auth";
import { UserIcon } from "@heroicons/react/24/outline";

export const UserMenu = () => {
  const queryClient = useQueryClient();
  const user = useQuery(["me"], getMe, userQuerySettings);

  const [isOpen, setIsOpen] = useState(false);

  const hoverOut = () => {
    setIsOpen(false);
  };

  const mutation = useMutation({
    mutationFn: (id) => {
      console.log("---> logging out user", user.data.email);

      return logoutUser();
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["me"]);
      window.location.href = "/";
    },
  });

  if (user.isLoading) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  return (
    <>
      <button
        type="button"
        className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        id="userMenuDropdownButton"
        aria-expanded="false"
        data-dropdown-toggle="userMenuDropdown"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="sr-only">Open user menu</span>
        <UserIcon
          className="h-7 w-7 text-gray-500 bg-gray-300 rounded-xl p-1"
          aria-hidden="true"
        />
      </button>
      <div
        className={`${
          isOpen ? "absolute" : "hidden"
        } top-0 right-1 w-48 py-2 mt-2 origin-top-right bg-cave-white border border-gray-200 rounded-md shadow-lg dark:bg-gray-800 dark:border-gray-700`}
        data-dropdown-content
        id="userMenuDropdown"
        onMouseLeave={hoverOut}
      >
        <div className="py-3 px-4">
          <span className="block text-sm font-semibold text-cave-black dark:text-cave-white">
            {user.data.first_name} {user.data.last_name}
          </span>
          <span className="block text-sm font-light text-gray-500 truncate dark:text-gray-400">
            {user.data.email}
          </span>
        </div>
        <ul
          className="py-1 font-light text-gray-500 dark:text-gray-400"
          aria-labelledby="userMenuDropdownButton"
        >
          <UserMenuItem onClick={() => mutation.mutate()}>Signout</UserMenuItem>
        </ul>
      </div>
    </>
  );
};
