import { toast } from "react-toastify";
import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
  jsonHeaders,
  makeRequest,
} from "./base";

export const getEventsCalendarSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getEventsCalendar = async (data) => {
  try {
    const url = `/api/events/calendar/`;
    return await postApiRequest(url, data);
  } catch (error) {
    console.error("Error fetching calendar events:", error);
    throw error;
  }
};

export const fetchYearlyEvents = async (data) => {
  const url = `/api/events/calendar/year/`;
  return await postApiRequest(url, data);
};

export const getEventsSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getEvents = async () => {
  const url = `/api/events/`;
  return await getApiRequest(url);
};

export const getEventsList = async ({ company_filter } = {}) => {
  const url = `/api/events/list/${
    company_filter ? `?company_filter=${company_filter}` : ""
  }`;
  return await getApiRequest(url);
};

export const getEventsDashboardList = async () => {
  const url = `/api/events/list/dashboard/`;
  return await getApiRequest(url);
};

export const getMyDrafts = async () => {
  const url = `/api/events/list/mydrafts/`;
  return await getApiRequest(url);
};

export const getOnSaleEvents = async () => {
  const url = `/api/events/onsale/`;
  return await getApiRequest(url);
};

export const recordTickets = async (eventID, manifestID, data) => {
  const url = `/api/events/${eventID}/ticket-manifests/${manifestID}/`;
  return await postApiRequest(url, data);
};

export const getEvent = async (id) => {
  const url = `/api/events/${id}/`;
  return await getApiRequest(url);
};

export const updateEvent = async (id, data) => {
  if (!id) throw new Error("Event ID is required");
  if (!data) throw new Error("Update data is required");

  const url = `/api/events/${id}/`;

  try {
    console.log("updateEvent - Request payload:", data); // Debug log

    // Ensure company field has a default value
    const normalizedData = {
      ...data,
      company: data.company || "mammoth",
    };

    // Validate required fields
    if (!normalizedData.name) {
      throw new Error("Event name is required");
    }
    if (!normalizedData.state) {
      throw new Error("Event state is required");
    }

    const headers = await jsonHeaders(true);
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(normalizedData),
      credentials: "same-origin",
    });

    if (!response.ok) {
      let errorMessage;
      try {
        const errorData = await response.json();
        if (typeof errorData === "object") {
          errorMessage = JSON.stringify(errorData, null, 2);
        } else {
          errorMessage =
            errorData.detail || errorData.message || "Server error occurred";
        }
      } catch {
        errorMessage = `HTTP Error ${response.status}`;
      }
      console.error("Server error response:", errorMessage); // Debug log
      throw new Error(errorMessage);
    }

    const responseData = await response.json();
    console.log("Server response:", responseData); // Debug log
    return responseData;
  } catch (error) {
    console.error("Error updating event:", error);
    throw error;
  }
};

export const updateEventAdvance = async (id, data) => {
  if (!id) throw new Error("Event ID is required");
  if (!data || typeof data !== "object")
    throw new Error("Invalid advance data format");

  const url = `/api/events/${id}/advance/`;

  try {
    const headers = await jsonHeaders(true);
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
      credentials: "same-origin",
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.detail || `Failed to update advance: ${response.status}`
      );
    }

    const responseData = await response.json();
    return responseData.advance;
  } catch (error) {
    console.error("Error updating advance:", error);
    throw error;
  }
};

export const patchEventAdvance = async (id, data) => {
  if (!id) throw new Error("Event ID is required");
  if (!data || typeof data !== "object")
    throw new Error("Invalid advance data format");

  const url = `/api/events/${id}/advance/`;

  try {
    const headers = await jsonHeaders(true);
    const response = await fetch(url, {
      method: "PATCH",
      headers,
      body: JSON.stringify(data),
      credentials: "same-origin",
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.detail || `Failed to patch advance: ${response.status}`
      );
    }

    const responseData = await response.json();
    return responseData.advance;
  } catch (error) {
    console.error("Error patching advance:", error);
    throw error;
  }
};

export const getEventAdvance = async (id) => {
  if (!id) throw new Error("Event ID is required");
  const url = `/api/events/${id}/advance/`;
  const response = await getApiRequest(url);
  return response;
};

export const updateEventAccounting = async (id, data) => {
  const url = `/api/events/accounting/${id}/`;
  return await putApiRequest(url, data);
};

export const createEvent = async (data) => {
  const url = `/api/events/`;
  return await postApiRequest(url, data);
};

export const getEventLinkSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getEventRoles = async () => {
  const url = `/api/events/people/roles/`;
  return await getApiRequest(url);
};

export const getEventPeople = async (id) => {
  const url = `/api/events/people/${id}/`;
  return await getApiRequest(url);
};

export const createEventPeople = async (id, data) => {
  const url = `/api/events/people/add/${id}/`;
  return await postApiRequest(url, data);
};

export const deleteEventPeople = async (id) => {
  const url = `/api/events/people/remove/${id}/`;
  return await deleteApiRequest(url);
};

export const toggleEventMainPOC = async (id) => {
  const url = `/api/events/people/toggle-main-contact/${id}/`;
  return await postApiRequest(url);
};

export const getEventLinks = async (id) => {
  const url = `/api/events/links/${id}/`;
  return await getApiRequest(url);
};

export const createEventLink = async (id, data) => {
  const url = `/api/events/links/${id}/`;
  return await postApiRequest(url, data);
};

export const deleteEventLink = async (id) => {
  const url = `/api/events/link/${id}/`;
  return await deleteApiRequest(url);
};

export const getEventCommentSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

export const getEventComments = async (id) => {
  const url = `/api/events/comments/${id}/`;
  return await getApiRequest(url);
};

export const createEventComment = async (id, data) => {
  const url = `/api/events/comments/${id}/`;
  return await postApiRequest(url, data);
};

export const getEventFileSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

//export const getEventFiles = async (id) => {
//  const url = `/api/events/files/${id}/`
//  return await getApiRequest(url)
//}

export const getEventFiles = async (id, marketingOnly = false) => {
  const url = `/api/events/files/${id}/${
    marketingOnly ? "?marketing_only=true" : ""
  }`;
  return await getApiRequest(url);
};

export const createEventFile = async (eventId, data) => {
  console.log("createEventFile called with eventId:", eventId);
  console.log("Data to be sent:", data);

  const url = `/api/events/files/${eventId}/`;
  console.log("Request URL:", url);

  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");

  // Create FormData and properly set checkbox values
  const formData = new FormData();

  // Handle file upload
  if (data.get("file")) {
    formData.append("file", data.get("file"));
  }

  // Ensure boolean values are properly stringified
  formData.append(
    "marketing",
    data.get("marketing") === "true" ? "true" : "false"
  );
  formData.append("final", data.get("final") === "true" ? "true" : "false");

  // Add description if present
  if (data.get("description")) {
    formData.append("description", data.get("description"));
  }

  try {
    const resp = await makeRequest(url, "POST", headers, formData);
    const jsonResponse = await resp.json();
    console.log("createEventFile response:", jsonResponse);
    return jsonResponse;
  } catch (error) {
    console.error("Error in createEventFile:", error);
    throw error;
  }
};

export const deleteEventFile = async (id) => {
  const url = `/api/events/file/${id}/`;
  return await deleteApiRequest(url);
};

export const getManifest = async (id, data) => {
  const url = `/api/events/manifest/${id}/`;
  return await getApiRequest(url);
};

export const updateManifest = async (id, data) => {
  const url = `/api/events/manifest/${id}/`;

  if (!id) {
    throw new Error("Manifest ID is required");
  }

  try {
    const headers = await jsonHeaders(true);
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
      credentials: "same-origin",
    });

    const responseData = await response.json();

    if (!response.ok) {
      const errorMessage =
        responseData.detail ||
        responseData.message ||
        `HTTP Error ${response.status}`;
      console.error("Server error response:", responseData);
      throw new Error(errorMessage);
    }

    return responseData;
  } catch (error) {
    console.error("Error updating manifest:", error);
    const message =
      error instanceof Error ? error.message : "Unknown error occurred";
    throw new Error(`Failed to update manifest: ${message}`);
  }
};

export const createEventManifest = async (id, data) => {
  const url = `/api/events/${id}/ticket-manifests/`;
  return await postApiRequest(url, data);
};

export const deleteEventManifest = async (eventID, manifestID) => {
  const url = `/api/events/${eventID}/delete-ticket-manifest/${manifestID}/`;
  return await postApiRequest(url);
};

export const markContractReceived = async (id) => {
  const url = `/api/events/contract-received/${id}/`;
  return await postApiRequest(url);
};

export const markContractReturned = async (id) => {
  const url = `/api/events/contract-returned/${id}/`;
  return await postApiRequest(url);
};

export const markContractFullyExecuted = async (id) => {
  const url = `/api/events/contract-fully-executed/${id}/`;
  return await postApiRequest(url);
};

export const createEventHolds = async (data) => {
  const url = `/api/events/make/holds/`;
  //return await postApiRequest(url);

  console.log("Creating event holds with data:", data);
  try {
    const response = await postApiRequest(url, { ...data });
    console.log("Event holds created successfully:", response);
    return response;
  } catch (error) {
    console.error("Error creating event holds:", error);
    throw error;
  }
};

export const getEventSettlementInfo = async (id) => {
  const url = `/api/events/settlement-info/${id}/`;
  return await getApiRequest(url);
};

export const getEventSettlement = async (id, book = "final") => {
  const url = `/api/accounting/event-settlement/${id}/${book}/`;
  return await getApiRequest(url);
};

export const updateEventSettlement = async (id, book, data) => {
  const url = `/api/accounting/event-settlement/${id}/${book}/`;
  return await putApiRequest(url, data);
};

export const getEventPDFs = async (id) => {
  const url = `/api/events/pdfs/${id}/`;
  return await getApiRequest(url);
};

export const createEventPDF = async (id, data) => {
  const url = `/api/events/pdf/create/${id}/`;
  return await postApiRequest(url, data);
};

export const updateEventOffer = async (id, data) => {
  const url = `/api/events/offer/${id}/`;
  return await postApiRequest(url, data);
};

export const getOffers = async () => {
  const url = `/api/offers/`;
  return await getApiRequest(url);
};

export const getOffer = async (id) => {
  const url = `/api/offers/${id}/`;
  return await getApiRequest(url);
};

export const updateOffer = async (id, data) => {
  const url = `/api/offers/${id}/`;
  return await putApiRequest(url, data);
};

export const createOffer = async (data) => {
  const url = `/api/offers/`;
  return await postApiRequest(url, data);
};

export const addEventToOffer = async (eventId, offerId) => {
  const url = `/api/offers/${offerId}/add-event/${eventId}/`;
  return await postApiRequest(url);
};

export const getEventOfferVariables = async (id) => {
  const url = `/api/events/offer/variables/${id}/`;
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "same-origin",
      headers: await jsonHeaders(),
    });
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage =
        errorData.detail || errorData.message || "An unknown error occurred";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      throw new Error(errorMessage);
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching offer variables:", error);
    throw error;
  }
};

export const getEventImageSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

export const getEventImages = async (id) => {
  const url = `/api/events/images/${id}/`;
  return await getApiRequest(url);
};

export const createEventImage = async (id, data) => {
  const url = `/api/events/images/add/${id}/`;
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  const resp = await makeRequest(url, "POST", headers, data);
  return resp.json();
};

export const deleteEventImage = async (id) => {
  const url = `/api/events/images/delete/${id}/`;
  return await deleteApiRequest(url);
};
export const getEventsBBList = async () => {
  const url = `/api/events/list/bb/`; // Remove the exclude_dismissed parameter
  const data = await getApiRequest(url);
  return data.filter((event) => event && event.id); // Filter out null/undefined events
};

export const getEventState = async (id) => {
  const url = `/api/events/${id}/get-state/`;
  const data = await getApiRequest(url);
  return data;
};

export const postEventState = async (id, data) => {
  const url = `/api/events/${id}/set-state/`;
  const headers = await jsonHeaders(true);
  const resp = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  if (!resp.ok) {
    const errorData = await resp.json();
    throw new Error(errorData.detail || "Failed to update event state");
  }
  return resp.json();
};

export const getEventDate = async (id) => {
  const url = `/api/events/get-date/${id}/`;
  const data = await getApiRequest(url);
  return data;
};

export const postEventDate = async (id, data) => {
  const url = `/api/events/set-date/${id}/`;
  const headers = await jsonHeaders(true);
  const resp = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  if (!resp.ok) {
    const errorData = await resp.json();
    throw new Error(errorData.detail || "Failed to update event date");
  }
  return resp.json();
};

export const postEventDuplicate = async (id) => {
  const url = `/api/events/${id}/duplicate/`;
  const data = await postApiRequest(url);
  return data;
};

export const getBuyerEvents = async (buyerId, isFuture = true) => {
  const url = `/api/events/list/buyer/${buyerId}/?is_future=${isFuture}`;
  return await getApiRequest(url);
};

export const createEventImages = async (eventId, data) => {
  console.log("createEventImage called with eventId:", eventId);
  console.log("Data to be sent:", data);
  const url = `/api/events/images/${eventId}/`;
  console.log("Request URL:", url);
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  try {
    const resp = await makeRequest(url, "POST", headers, data);
    const jsonResponse = await resp.json();
    console.log("createEventImage response:", jsonResponse);
    return jsonResponse;
  } catch (error) {
    console.error("Error in createEventImage:", error);
    throw error;
  }
};
